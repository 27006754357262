import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Header from './Header';
import SideBar from './SideBar';
import Footer from './Footer';

const Layout = ({ children, title }) => (
  <>
    <Helmet>
      <title>The KPI Portal Admin - {title}</title>
    </Helmet>
    <div className="flexible-content h-100 position-relative">
      <Header title={title} />
      <SideBar />
      <main id="content" className="p-5">
        <div className="mb-5">{children}</div>
      </main>
      <Footer />
    </div>
  </>
);

export default Layout;

Layout.defaultProps = {
  title: 'Home',
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};
