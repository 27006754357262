import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MDBCol, MDBRow, MDBTypography } from 'mdbreact';
import MUIDataTable from 'mui-datatables';

import {
  fetchPayments,
  setCurrentPage,
  setRowsPerPage,
} from '../../lib/actions/payments';
import Layout from '../Layout';

const Payments = () => {
  const dispatch = useDispatch();

  const {
    payments = [],
    totalCount,
    currentPage,
    rowsPerPage,
  } = useSelector((state) => state.payments);

  useEffect(() => {
    dispatch(fetchPayments());
  }, []);

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'description',
      label: 'Description',
    },
    {
      name: 'net',
      label: 'Net Amount',
      options: {
        customBodyRender: (value) => value / 100,
      },
    },
    {
      name: 'amount',
      label: 'Gross Amount',
      options: {
        customBodyRender: (value) => value / 100,
      },
    },
    {
      name: 'fee',
      label: 'Total Fees',
      options: {
        customBodyRender: (value) => value / 100,
      },
    },
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'created',
      label: 'Created On',
      options: {
        customBodyRender: (value) =>
          moment(value * 1000).format('MMMM Do YYYY, h:mm a'),
      },
    },
  ];

  const dataTableOptions = {
    selectableRows: 'none',
    count: totalCount,
    page: currentPage,
    onChangePage: (changedPage) => {
      dispatch(setCurrentPage(changedPage));
      if (payments.length) {
        // if clicked on next page
        if (currentPage < changedPage) {
          const lastPaymentId = payments[(currentPage + 1) * rowsPerPage - 1].id;
          dispatch(fetchPayments(null, lastPaymentId));
        }
        // if clicked on previous page
        else {
          const firstPaymentId = payments[currentPage * rowsPerPage].id;
          dispatch(fetchPayments(firstPaymentId));
        }
      }
    },
    onChangeRowsPerPage: (numberOfRows) => {
      dispatch(setRowsPerPage(numberOfRows));
      dispatch(fetchPayments());
    },
  };

  return (
    <Layout title="Payments">
      <MDBRow>
        <MDBCol lg="12">
          {payments.length !== 0 && (
            <MUIDataTable
              title="List of Payments"
              data={payments}
              columns={columns}
              options={dataTableOptions}
            />
          )}
          {payments.length === 0 && (
            <div className="text-center">
              <MDBTypography variant="h4">No Payments Found</MDBTypography>
            </div>
          )}
        </MDBCol>
      </MDBRow>
    </Layout>
  );
};

export default Payments;
