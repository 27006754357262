import {
  CLOSE_SET_GLOBAL_PI_MODAL,
  OPEN_SET_GLOBAL_PI_MODAL,
  SET_CUSTOM_PI,
  SET_CUSTOM_PIS,
} from '../actions/constants';

const initialState = {
  customPIs: [],
  isModalOpen: false,
  customPI: {},
};

const customPIs = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CUSTOM_PIS:
      return {
        ...state,
        customPIs: payload,
      };
    case SET_CUSTOM_PI:
      return {
        ...state,
        customPI: payload,
      };
    case OPEN_SET_GLOBAL_PI_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };
    case CLOSE_SET_GLOBAL_PI_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };
    default:
      return state;
  }
};

export default customPIs;
