import {
  CLOSE_SUPPLIER_MODAL,
  OPEN_SUPPLIER_MODAL,
  SET_SUPPLIER,
  SET_SUPPLIERS,
} from '../actions/constants';

const initialState = {
  suppliers: [],
  isModalOpen: false,
  supplier: {},
};

const suppliers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SUPPLIERS:
      return {
        ...state,
        suppliers: payload,
      };
    case OPEN_SUPPLIER_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };
    case CLOSE_SUPPLIER_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };
    case SET_SUPPLIER:
      return {
        ...state,
        supplier: payload,
      };
    default:
      return state;
  }
};

export default suppliers;
