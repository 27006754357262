import axios from 'axios';

import {
  CLOSE_SUPPLIER_MODAL,
  OPEN_SUPPLIER_MODAL,
  SET_SUPPLIER,
  SET_SUPPLIERS,
} from './constants';
import { hideLoader, showErrorMessage, showLoader } from './common';

const setSuppliers = (suppliers) => ({
  type: SET_SUPPLIERS,
  payload: suppliers,
});

const openModal = () => ({
  type: OPEN_SUPPLIER_MODAL,
});

const closeModal = () => ({
  type: CLOSE_SUPPLIER_MODAL,
});

const setSupplier = (supplier) => ({
  type: SET_SUPPLIER,
  payload: supplier,
});

const fetchSuppliers = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  dispatch(showLoader());
  try {
    const { data: suppliers } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/suppliers`,
      { params: { access_token: accessToken } },
    );
    dispatch(setSuppliers(suppliers));
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while fetching suppliers due to error '${error.message}'`;
    showErrorMessage(message);
  }
  dispatch(hideLoader());
};

const updateSupplierDetails =
  ({ name, address, phoneNumber }) =>
  async (dispatch, getState) => {
    const {
      loginDetails: { id: accessToken },
    } = getState().common;
    const {
      supplier: { id: supplierId },
    } = getState().suppliers;
    dispatch(showLoader());
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/suppliers/${supplierId}`,
        { name, address, phoneNumber },
        { params: { access_token: accessToken } },
      );
      dispatch(closeModal());
      dispatch(fetchSuppliers());
    } catch (error) {
      dispatch(hideLoader());
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        `Error occurred while updating supplier details due to error '${error.message}'`;
      showErrorMessage(message);
    }
  };

const toggleStatus = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  const {
    supplier: { id: supplierId },
  } = getState().suppliers;
  dispatch(showLoader());
  try {
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/suppliers/${supplierId}/toggleStatus`,
      null,
      { params: { access_token: accessToken } },
    );
    dispatch(fetchSuppliers());
  } catch (error) {
    dispatch(hideLoader());
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while updating supplier status due to error '${error.message}'`;
    showErrorMessage(message);
  }
};

export {
  setSuppliers,
  fetchSuppliers,
  openModal,
  closeModal,
  setSupplier,
  updateSupplierDetails,
  toggleStatus,
};
