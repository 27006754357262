import axios from 'axios';

import { SET_REPORT, SET_REPORTS } from './constants';
import { hideLoader, showErrorMessage, showLoader } from './common';

const setReports = (reports) => ({
  type: SET_REPORTS,
  payload: reports,
});

const setReport = (report) => ({
  type: SET_REPORT,
  payload: report,
});

const fetchReports = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  dispatch(showLoader());
  try {
    const { data: reports = [] } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/reports?filter[include]=client&filter[include]=supplier`,
      {
        params: {
          access_token: accessToken,
        },
      },
    );
    const customReports = reports.map(
      ({
        client: { name: clientName, id: clientId },
        supplier: { name: supplierName, id: supplierId },
        kpis,
        ...restOfReports
      }) => ({
        ...restOfReports,
        clientName,
        supplierName,
        clientId,
        supplierId,
      }),
    );
    dispatch(setReports(customReports));
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while fetching reports due to error '${error.message}'`;
    showErrorMessage(message);
  }
  dispatch(hideLoader());
};

const toggleStatus = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  const {
    report: { id: reportId },
  } = getState().reports;
  dispatch(showLoader());
  try {
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/reports/${reportId}/toggleStatus`,
      null,
      { params: { access_token: accessToken } },
    );
    dispatch(fetchReports());
  } catch (error) {
    dispatch(hideLoader());
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while updating report status due to error '${error.message}'`;
    showErrorMessage(message);
  }
};

export { setReports, fetchReports, toggleStatus, setReport };
