import React from 'react';
import PropTypes from 'prop-types';

const CustomSelect = ({ options, label, input: { onChange, value } }) => (
  <div className="form-group">
    <label htmlFor={`customFormSelect${label}`}>{label}</label>
    <select
      className="form-control"
      id={`customFormSelect${label}`}
      onChange={onChange}
      value={value}
      aria-label={label}
    >
      {options &&
        options.length > 0 &&
        options.map((element) => (
          <option selected={element.selected} value={element.value}>
            {element.label}
          </option>
        ))}
    </select>
  </div>
);
export default CustomSelect;

CustomSelect.defaultProps = {
  options: [],
  label: null,
  input: { value: null, onChange: null, name: null },
};

CustomSelect.propTypes = {
  options: PropTypes.instanceOf(Array),
  label: PropTypes.string,
  input: PropTypes.instanceOf(Object),
};
