import React from 'react';
import { Field, propTypes, reduxForm } from 'redux-form';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import Logo from '../assets/logo.png';
import CustomField from './CustomField';
import { isRequired } from '../lib/formValidations';
import { login } from '../lib/actions/common';

const formName = 'loginForm';

const Login = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  return (
    <div className="login-root">
      <Helmet>
        <title>The KPI Portal Admin - Login</title>
      </Helmet>
      <form
        className="form-signin"
        onSubmit={(ev)=>{
          ev.nativeEvent.preventDefault();
          handleSubmit((validatedValues) => {
            console.log('validatedValues', validatedValues);
            dispatch(login(validatedValues));
          })(ev);
        }}
      >
        <img className="mb-4" src={Logo} alt="logo" width={72} height={72} />
        <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
        <Field
          name="username"
          component={CustomField}
          validate={isRequired}
          type="text"
          id="inputEmail"
          className="form-control"
          placeholder="Username"
        />
        <Field
          name="password"
          component={CustomField}
          validate={isRequired}
          type="password"
          className="form-control"
          placeholder="Password"
        />
        <div className="checkbox mb-3">
          <label>
            <input type="checkbox" defaultValue="remember-me" /> Remember me
          </label>
        </div>
        <button className="btn btn-lg btn-primary btn-block" type="submit">
          Sign in
        </button>
        <p className="mt-5 mb-3 text-muted text-center">
          © Copyright {new Date().getFullYear()}
          <div>Evolve Business Excellence Limited.</div>
          <div>All rights reserved.</div>
        </p>
      </form>
    </div>
  );
};

export default reduxForm({ form: formName })(Login);

Login.propTypes = {
  ...propTypes,
};
