import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBRow } from 'mdbreact';
import { Field, propTypes, reduxForm } from 'redux-form';

import { closeModal, updateInternalSupplierUserDetails } from '../lib/actions/internalSupplierUsers';
import CustomField, { CustomTextareaField } from './CustomField';
import { isRequired } from '../lib/formValidations';
import CustomFieldCheckbox from './CustomFieldCheckbox';

const formName = 'internalSupplierUser-form';

const EditInternalSupplierUserModal = ({ handleSubmit, reset, pristine }) => {
  const dispatch = useDispatch();
  const { isModalOpen } = useSelector((state) => state.internalSupplierUsers);

  return (
    <MDBModal
      inline={false}
      noClickableBodyWithoutBackdrop
      overflowScroll
      isOpen={isModalOpen}
      toggle={() => dispatch(closeModal())}
      size="lg"
    >
      <MDBModalHeader>View/Edit Internal Supplier User</MDBModalHeader>
      <form
        onSubmit={handleSubmit(({ name }) => {
          dispatch(updateInternalSupplierUserDetails({ name }));
        })}
      >
        <MDBModalBody>
          <MDBRow>
            <MDBCol lg="4">
              <Field
                label="Name"
                component={CustomField}
                name="name"
                validate={isRequired}
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                label="Role"
                component={CustomField}
                name="role"
                disabled
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                style={{"resize": "none"}}
                label="Email"
                component={CustomTextareaField}
                name="email"
                disabled
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                label="Username"
                component={CustomField}
                name="username"
                disabled
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                label="Created"
                component={CustomField}
                name="createdAt"
                disabled
                type="datetime"
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                label="Last Updated"
                component={CustomField}
                name="updatedAt"
                disabled
                type="datetime"
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                label="Last Login"
                component={CustomField}
                name="lastLogin"
                disabled
                type="datetime"
              />
            </MDBCol>
            <MDBCol lg="12">
              <MDBRow>
                <MDBCol lg="3">
                  <Field
                    label="Status"
                    component={CustomFieldCheckbox}
                    name="isActive"
                    disabled
                  />
                </MDBCol>
                <MDBCol lg="3">
                  <Field
                    label="Email Delivery Failed"
                    component={CustomFieldCheckbox}
                    name="hasFailedDelivery"
                    disabled
                  />
                </MDBCol>
                <MDBCol lg="3">
                  <Field
                    label="Notifications"
                    component={CustomFieldCheckbox}
                    name="enableNotification"
                    disabled
                  />
                </MDBCol>
                <MDBCol lg="3">
                  <Field
                    label="Email Verified"
                    component={CustomFieldCheckbox}
                    name="emailVerified"
                    disabled
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="secondary"
            onClick={() => {
              dispatch(reset());
              dispatch(closeModal());
            }}
          >
            Close
          </MDBBtn>
          <MDBBtn type="submit" color="primary" disabled={pristine}>
            Save changes
          </MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBModal>
  );
};

const EditInternalSupplierUserModalReduxForm = reduxForm({ form: formName })(
  EditInternalSupplierUserModal,
);

export default connect((state) => ({
  initialValues: state.internalSupplierUsers.internalSupplierUser,
  enableReinitialize: true,
}))(EditInternalSupplierUserModalReduxForm);

EditInternalSupplierUserModal.propTypes = {
  ...propTypes,
};
