import { Notyf } from 'notyf';
import axios from 'axios';

import {
  HIDE_LOADER,
  RESET_COMMON_DETAILS,
  SET_ADMIN_USER_DETAILS,
  SET_LOGIN_DETAILS,
  SHOW_LOADER,
  TOGGLE_HEADER,
  FILTERED_DATA,
} from './constants';
import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  USER_ID_LOCAL_STORAGE_KEY,
} from '../constants';

const toast = new Notyf({
  position: { x: 'right', y: 'top' },
  dismissible: true,
  duration: 3000,
});

const showErrorMessage = (message) => {
  toast.error(message);
};

const showSuccessMessage = (message) => {
  toast.success(message);
};

const showLoader = () => ({
  type: SHOW_LOADER,
});

const hideLoader = () => ({
  type: HIDE_LOADER,
});

const setLoginDetails = (loginDetails) => ({
  type: SET_LOGIN_DETAILS,
  payload: loginDetails,
});

const setAdminUserDetails = (adminUserDetails) => ({
  type: SET_ADMIN_USER_DETAILS,
  payload: adminUserDetails,
});

const resetCommonDetails = () => {
  window.localStorage.clear();
  return {
    type: RESET_COMMON_DETAILS,
  };
};

const toggleHeader = () => ({
  type: TOGGLE_HEADER,
});

const fetchAdminUserDetails = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken, userId },
  } = getState().common;
  dispatch(showLoader());
  try {
    const { data: adminUserDetails } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/admins/${userId}`,
      {
        params: { access_token: accessToken },
      },
    );
    dispatch(setAdminUserDetails(adminUserDetails));
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while fetching admin user details due to error '${error.message}'`;
    showErrorMessage(message);
  }
  dispatch(hideLoader());
};

const filteredDataDownload =
  (userIdsForCSV, filteredType) => async (dispatch, getState) => {
    console.log('userIdsForCSV', userIdsForCSV);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/downloadClient_Details`,
        { id: userIdsForCSV, requestType: filteredType },
      );
      const fileUrlDomain = process.env.REACT_APP_API_BASE_URL.split('/')
        .slice(0, 3)
        .join('/');
      const fileDownloadUrl = `${fileUrlDomain}/${data.filename}`;
      window.open(fileDownloadUrl);
    } catch (error) {
      console.log('error', error);
    }
  };

const login =
  ({ username, password }) =>
  async (dispatch) => {
    dispatch(showLoader());
    try {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', `${process.env.REACT_APP_API_BASE_URL}/admins/login`);
      xhr.send(JSON.stringify({ username, password }));
      xhr.onload = function () {
        dispatch(hideLoader());
        if (xhr.status != 200) {
          const response = JSON.parse(xhr.response);
          const message =
            (response && response.errorMessage) ||
            `Error occurred while logging in due to error '${': Unknown'}'`;
          showErrorMessage(message);
        } else {
          const loginDetails = JSON.parse(xhr.response);
          dispatch(setLoginDetails(loginDetails));
          dispatch(fetchAdminUserDetails());
          console.log('loginDetails', loginDetails);
          const { id, userId } = loginDetails;
          window.localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, id);
          window.localStorage.setItem(USER_ID_LOCAL_STORAGE_KEY, userId);
        }
      };
      xhr.onerror = function () {
        showErrorMessage('Request failed');
      };
    } catch (error) {
      dispatch(hideLoader());
    }
  };

const logout = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  dispatch(showLoader());
  try {
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/admins/logout`,
      null,
      {
        params: { access_token: accessToken },
      },
    );
    dispatch(resetCommonDetails());
    window.location.assign('/');
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while logging in due to error '${error.message}'`;
    showErrorMessage(message);
  }
  dispatch(hideLoader());
};

export {
  showSuccessMessage,
  showErrorMessage,
  showLoader,
  hideLoader,
  setLoginDetails,
  setAdminUserDetails,
  fetchAdminUserDetails,
  login,
  logout,
  resetCommonDetails,
  toggleHeader,
  filteredDataDownload,
};
