import {
  CLOSE_INTERNAL_SUPPLIER_USER_MODAL,
  OPEN_INTERNAL_SUPPLIER_USER_MODAL,
  SET_INTERNAL_SUPPLIER_USER,
  SET_INTERNAL_SUPPLIER_USERS,
} from '../actions/constants';

const initialState = {
  internalSupplierUsers: [],
  isModalOpen: false,
  internalSupplierUser: {},
};

const internalSupplierUsers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INTERNAL_SUPPLIER_USERS:
      return {
        ...state,
        internalSupplierUsers: payload,
      };
    case OPEN_INTERNAL_SUPPLIER_USER_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };
    case CLOSE_INTERNAL_SUPPLIER_USER_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };
    case SET_INTERNAL_SUPPLIER_USER:
      return {
        ...state,
        internalSupplierUser: payload,
      };
    default:
      return state;
  }
};

export default internalSupplierUsers;
