export const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'accessToken';
export const USER_ID_LOCAL_STORAGE_KEY = 'userId';
export const frequencyOptions = [
  {
    label: 'Monthly',
    value: 'Monthly',
    selected: true,
  },
  {
    label: 'Yearly',
    value: 'Yearly',
    selected: false,
  },
  {
    label: 'Quarterly',
    value: 'Quarterly',
    selected: false,
  },
  {
    label: 'Half yearly',
    value: 'Half yearly',
    selected: false,
  },
];

export const categoryOptions = [
  {
    label: 'Total Achieved per month / Total available per month',
    value: 'Total Achieved per month / Total available per month',
    selected: true,
  },
  {
    label: 'Exception Reporting',
    value: 'Exception Reporting',
    selected: false,
  },
  {
    label: 'Percentage of Total achieved',
    value: 'Percentage of Total achieved',
    selected: false,
  },
];
