import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { MDBCol, MDBRow, MDBTypography } from 'mdbreact';
import MUIDataTable from 'mui-datatables';

import {
  fetchReports,
  setReport,
  toggleStatus,
} from '../../lib/actions/reports';
import Layout from '../Layout';

const Reports = () => {
  const dispatch = useDispatch();

  const { reports = [] } = useSelector((state) => state.reports);

  useEffect(() => {
    dispatch(fetchReports());
  }, []);

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'month',
      label: 'Month',
    },
    {
      name: 'year',
      label: 'Year',
    },
    {
      name: 'scoringCriteria',
      label: 'Scoring Criteria',
    },
    {
      name: 'startDate',
      label: 'Start Date',
      options: {
        customBodyRender: (value) =>
          moment(value).format('MMMM Do YYYY, h:mm a'),
      },
    },
    {
      name: 'clientName',
      label: 'Client',
    },
    {
      name: 'supplierName',
      label: 'Supplier',
    },
    {
      name: 'isFinalized',
      label: 'Finalized',
      options: {
        customBodyRender: (value) => (value ? 'Yes' : 'No'),
      },
    },
    {
      name: 'isActive',
      label: 'Access',
      options: {
        customBodyRender: (value, { rowIndex, currentTableData }) => (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`customSwitchesChecked${rowIndex}`}
              checked={value}
              onChange={() => {
                const selectedReport =
                  reports[currentTableData[rowIndex].index];
                dispatch(setReport(selectedReport));
                dispatch(toggleStatus());
              }}
            />
            <label
              className="custom-control-label cursor-pointer"
              htmlFor={`customSwitchesChecked${rowIndex}`}
            />
          </div>
        ),
      },
    },
    {
      name: 'clientId',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'supplierId',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'isFinalized',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'createdAt',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'updatedAt',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
  ];

  const dataTableOptions = {
    selectableRows: 'none',
  };

  return (
    <Layout title="Reports">
      <MDBRow>
        <MDBCol lg="12">
          {reports.length !== 0 && (
            <MUIDataTable
              title="List of Reports"
              data={reports}
              columns={columns}
              options={dataTableOptions}
            />
          )}
          {reports.length === 0 && (
            <div className="text-center">
              <MDBTypography variant="h4">No Reports Found</MDBTypography>
            </div>
          )}
        </MDBCol>
      </MDBRow>
    </Layout>
  );
};

export default Reports;
