import React, { useEffect, useRef } from 'react';
import { MDBCol, MDBRow, MDBTypography } from 'mdbreact';
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../Layout';
import {
  fetchInternalClientUsers,
  openModal,
  setInternalClientUser,
  toggleStatus,
} from '../../lib/actions/internalClientUsers';
import { filteredDataDownload } from '../../lib/actions/common';
import EditInternalClientUserModal from '../EditInternalClientUserModal';

const InternalClientUsers = () => {
  const dispatch = useDispatch();
  const filteredUsersIds = useRef([]);

  const { internalClientUsers = [] } = useSelector(
    (state) => state.internalClientUsers,
  );

  const downloadCsvHandler = () => {
    console.log('downloadCsvHandler');
    let userIdsForCSV;
    if(filteredUsersIds.current && filteredUsersIds.current.length) {
      userIdsForCSV = filteredUsersIds.current;
    } else {
      userIdsForCSV = internalClientUsers.map(internalClientUserItem=>internalClientUserItem.id);
    }
    console.log('userIdsForCSV:', userIdsForCSV);
    dispatch(filteredDataDownload(userIdsForCSV, "internalClientUser"));
  }

  useEffect(() => {
    dispatch(fetchInternalClientUsers());
  }, []);

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        hint: "Click on a specific internal client user's name to View/Edit the details",
        customBodyRender: (value, { rowIndex, currentTableData }) => (
          <div
            className="cursor-pointer"
            onClick={() => {
              const selectedInternalClientUser =
                internalClientUsers[currentTableData[rowIndex].index];
              dispatch(setInternalClientUser(selectedInternalClientUser));
              dispatch(openModal());
            }}
            onKeyPress={() => {
              const selectedInternalClientUser =
                internalClientUsers[currentTableData[rowIndex].index];
              dispatch(setInternalClientUser(selectedInternalClientUser));
              dispatch(openModal());
            }}
            tabIndex="0"
            role="button"
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'username',
      label: 'Username',
    },
    {
      name: 'role',
      label: 'Role',
    },
    {
      name: 'isActive',
      label: 'Access',
      options: {
        customBodyRender: (value, { rowIndex, currentTableData }) => (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`customSwitchesChecked${rowIndex}`}
              checked={value}
              onChange={() => {
                const selectedInternalClientUser =
                  internalClientUsers[currentTableData[rowIndex].index];
                dispatch(setInternalClientUser(selectedInternalClientUser));
                dispatch(toggleStatus());
              }}
            />
            <label
              className="custom-control-label cursor-pointer"
              htmlFor={`customSwitchesChecked${rowIndex}`}
            />
          </div>
        ),
      },
    },
    {
      name: 'emailVerified',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'enableNotification',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'hasFailedDelivery',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'forgotPassword',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'lastLogin',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'createdAt',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'updatedAt',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
  ];

  const dataTableOptions = {
    selectableRows: 'none',
    download: true,
    onFilterChange:(_1, _2, _3, _4, data) => {
      const userIds = data.map(item=>item.data[0]);
      filteredUsersIds.current = userIds;
    }
  };

   // Creating custom CSV button
   useEffect(()=>{
    if(internalClientUsers && internalClientUsers.length){
      
      const downloadCsvEle = document.querySelector('[data-testid="DownloadCSV-iconButton"]');
      const x = downloadCsvEle.outerHTML;
      downloadCsvEle.remove();
      const downloadCsvPortalElement = document.createElement('div');
      downloadCsvPortalElement.setAttribute('id','download-csv-portal');
      downloadCsvPortalElement.style.display = 'inline-block';
      document.querySelector('[data-testid="Search-iconButton"]').after(downloadCsvPortalElement);
      downloadCsvPortalElement.innerHTML= x;
      document.querySelector('[data-testid="DownloadCSV-iconButton"]').addEventListener('click', downloadCsvHandler);
      // downloadCsvPortalElement
    }
  },[internalClientUsers])

  return (
    <Layout title="Internal Client Users">
      <EditInternalClientUserModal />
      <MDBRow>
        <MDBCol lg="12">
          {internalClientUsers.length !== 0 && (
            <MUIDataTable
              title="List of Internal Client Users"
              data={internalClientUsers}
              columns={columns}
              options={dataTableOptions}
            />
          )}
          {internalClientUsers.length === 0 && (
            <div className="text-center">
              <MDBTypography variant="h4">
                No Internal Client Users Found
              </MDBTypography>
            </div>
          )}
        </MDBCol>
      </MDBRow>
    </Layout>
  );
};

export default InternalClientUsers;
