import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from 'mdbreact';
import { Field, propTypes, reduxForm } from 'redux-form';

import { closeSetGlobalPIModal, setAsGlobalPI } from '../lib/actions/customPIs';
import CustomField from './CustomField';
import { isRequired, isValidTarget } from '../lib/formValidations';
import CustomSelect from './CustomSelect';
import { categoryOptions, frequencyOptions } from '../lib/constants';

const formName = 'customPI-form';

const SetGlobalPiModal = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { isModalOpen } = useSelector((state) => state.customPIs);

  return (
    <MDBModal
      inline={false}
      noClickableBodyWithoutBackdrop
      overflowScroll
      isOpen={isModalOpen}
      toggle={() => dispatch(closeSetGlobalPIModal())}
      size="md"
    >
      <MDBModalHeader>Set As Global PI</MDBModalHeader>
      <form
        onSubmit={handleSubmit((newCustomPI) => {
          dispatch(setAsGlobalPI(newCustomPI));
        })}
      >
        <MDBModalBody>
          <MDBRow>
            <MDBCol lg="6">
              <Field
                label="Name"
                component={CustomField}
                name="name"
                validate={isRequired}
              />
            </MDBCol>
            <MDBCol lg="6">
              <Field
                label="Methodology"
                component={CustomField}
                name="methodology"
                validate={isRequired}
              />
            </MDBCol>
            <MDBCol lg="6">
              <Field
                label="Frequency"
                component={CustomSelect}
                name="frequency"
                options={frequencyOptions}
              />
            </MDBCol>
            <MDBCol lg="6">
              <Field
                label="Target"
                component={CustomField}
                name="target"
                type="number"
                validate={isValidTarget}
              />
            </MDBCol>
            <MDBCol lg="12">
              <Field
                label="Category"
                component={CustomSelect}
                name="category"
                options={categoryOptions}
              />
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="secondary"
            onClick={() => {
              dispatch(closeSetGlobalPIModal());
            }}
          >
            Close
          </MDBBtn>
          <MDBBtn color="primary" type="submit">
            Set As Global
          </MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBModal>
  );
};

const SetGlobalPiModalReduxForm = reduxForm({ form: formName })(
  SetGlobalPiModal,
);

export default connect((state) => ({
  initialValues: state.customPIs.customPI,
  enableReinitialize: true,
}))(SetGlobalPiModalReduxForm);

SetGlobalPiModal.propTypes = {
  ...propTypes,
};
