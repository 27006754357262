import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBBtn, MDBCol, MDBIcon, MDBRow, MDBTypography } from 'mdbreact';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';

import Layout from '../Layout';
import {
  fetchAdmins,
  openCreateAdminModal,
  openSetPasswordModal,
  setAdmin,
  toggleStatus,
} from '../../lib/actions/admins';
import AddAdminUserModal from '../AddAdminUserModal';
import SetAdminPasswordModal from '../SetAdminPasswordModal';

const Admins = () => {
  const dispatch = useDispatch();

  const { admins = [] } = useSelector((state) => state.admins);

  useEffect(() => {
    dispatch(fetchAdmins());
  }, []);

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'username',
      label: 'Username',
      options: {
        hint: 'Click on a specific admin username to set password',
        customBodyRender: (value, { rowIndex, currentTableData }) => (
          <>
            {value !== process.env.REACT_APP_SUPER_ADMIN_USERNAME ? (
              <div
                className="cursor-pointer"
                onClick={() => {
                  const selectedAdmin =
                    admins[currentTableData[rowIndex].index];
                  dispatch(setAdmin(selectedAdmin));
                  dispatch(openSetPasswordModal());
                }}
                onKeyPress={() => {
                  const selectedAdmin =
                    admins[currentTableData[rowIndex].index];
                  dispatch(setAdmin(selectedAdmin));
                  dispatch(openSetPasswordModal());
                }}
                tabIndex="0"
                role="button"
              >
                {value}
              </div>
            ) : (
              value
            )}
          </>
        ),
      },
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'createdAt',
      label: 'Created On',
      options: {
        customBodyRender: (value) =>
          moment(value).format('MMMM Do YYYY, h:mm a'),
      },
    },
    {
      name: 'isActive',
      label: 'status',
      options: {
        customBodyRender: (value, { rowIndex, currentTableData }) => (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`customSwitchesChecked${rowIndex}`}
              checked={value}
              onChange={() => {
                const selectedAdmin = admins[currentTableData[rowIndex].index];
                dispatch(setAdmin(selectedAdmin));
                dispatch(toggleStatus());
              }}
              disabled={
                admins[currentTableData[rowIndex].index].username ===
                process.env.REACT_APP_SUPER_ADMIN_USERNAME
              }
            />
            <label
              className="custom-control-label cursor-pointer"
              htmlFor={`customSwitchesChecked${rowIndex}`}
            />
          </div>
        ),
      },
    },
    {
      name: 'emailVerified',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'updatedAt',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
  ];

  const dataTableOptions = {
    selectableRows: 'none',
  };

  return (
    <Layout title="Admins">
      <AddAdminUserModal />
      <SetAdminPasswordModal />
      <MDBRow>
        <MDBCol lg="12">
          {admins.length !== 0 && (
            <MUIDataTable
              title="List of Admins"
              data={admins}
              columns={columns}
              options={dataTableOptions}
            />
          )}
          {admins.length === 0 && (
            <div className="text-center">
              <MDBTypography variant="h4">No Admin Users Found</MDBTypography>
            </div>
          )}
        </MDBCol>
      </MDBRow>
      <div className="add-button-div">
        <MDBBtn
          onClick={() => dispatch(openCreateAdminModal())}
          color="primary"
          className="add-button"
        >
          <MDBIcon icon="plus" size="2x" />
        </MDBBtn>
      </div>
    </Layout>
  );
};

export default Admins;
