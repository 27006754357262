import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from 'mdbreact';
import { Field, propTypes, reduxForm } from 'redux-form';
import CustomField, { CustomTextareaField } from './CustomField';
import {
  isEmail,
  isPasswordSame,
  isRequired,
  isStrongPassword,
} from '../lib/formValidations';
import { closeCreateAdminModal, createAdminUser } from '../lib/actions/admins';

const formName = 'add-admin-form';

const AddAdminUserModal = ({ handleSubmit, reset, pristine }) => {
  const dispatch = useDispatch();
  const { isCreateModalOpen } = useSelector((state) => state.admins);

  useEffect(() => {
    // close after creating admin user
    if (!isCreateModalOpen) {
      dispatch(reset());
    }
  }, [isCreateModalOpen]);

  return (
    <MDBModal
      inline={false}
      noClickableBodyWithoutBackdrop
      overflowScroll
      isOpen={isCreateModalOpen}
      toggle={() => dispatch(closeCreateAdminModal())}
      size="md"
    >
      <MDBModalHeader>Add Admin</MDBModalHeader>
      <form
        onSubmit={handleSubmit(({ email, username, newPassword }) => {
          dispatch(createAdminUser({ email, username, password: newPassword }));
        })}
      >
        <MDBModalBody>
          <MDBRow>
            <MDBCol lg="6">
              <Field
                label="Username"
                component={CustomField}
                name="username"
                validate={isRequired}
              />
            </MDBCol>
            <MDBCol lg="6">
              <Field
                style={{"resize": "none"}}
                label="Email"
                component={CustomTextareaField}
                name="email"
                validate={[isRequired, isEmail]}
              />
            </MDBCol>
            <MDBCol lg="6">
              <Field
                label="Password"
                component={CustomField}
                name="newPassword"
                type="password"
                validate={[isRequired, isStrongPassword]}
              />
            </MDBCol>
            <MDBCol lg="6">
              <Field
                label="Confirm Password"
                component={CustomField}
                name="confirmNewPassword"
                type="password"
                validate={[isRequired, isStrongPassword, isPasswordSame]}
              />
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="secondary"
            onClick={() => {
              dispatch(reset());
              dispatch(closeCreateAdminModal());
            }}
          >
            Close
          </MDBBtn>
          <MDBBtn type="submit" color="primary" disabled={pristine}>
            Create
          </MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBModal>
  );
};

export default reduxForm({ form: formName })(AddAdminUserModal);

AddAdminUserModal.propTypes = {
  ...propTypes,
};
