import axios from 'axios';

import {
  CLOSE_INTERNAL_CLIENT_USER_MODAL,
  OPEN_INTERNAL_CLIENT_USER_MODAL,
  SET_INTERNAL_CLIENT_USER,
  SET_INTERNAL_CLIENT_USERS,
} from './constants';
import { hideLoader, showErrorMessage, showLoader } from './common';

const baseAPIURL = '/internalClientUsers';

const setInternalClientUsers = (internalClientUsers) => ({
  type: SET_INTERNAL_CLIENT_USERS,
  payload: internalClientUsers,
});

const openModal = () => ({
  type: OPEN_INTERNAL_CLIENT_USER_MODAL,
});

const closeModal = () => ({
  type: CLOSE_INTERNAL_CLIENT_USER_MODAL,
});

const setInternalClientUser = (internalClientUser) => ({
  type: SET_INTERNAL_CLIENT_USER,
  payload: internalClientUser,
});

const fetchInternalClientUsers = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  dispatch(showLoader());
  try {
    const { data: internalClientUsers } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}${baseAPIURL}`,
      { params: { access_token: accessToken } },
    );
    dispatch(setInternalClientUsers(internalClientUsers));
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while fetching internal client users due to error '${error.message}'`;
    showErrorMessage(message);
  }
  dispatch(hideLoader());
};

const updateInternalClientUserDetails =
  ({ name }) =>
  async (dispatch, getState) => {
    const {
      loginDetails: { id: accessToken },
    } = getState().common;
    const {
      internalClientUser: { id: internalClientUserId },
    } = getState().internalClientUsers;
    dispatch(showLoader());
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}${baseAPIURL}/${internalClientUserId}`,
        { name },
        { params: { access_token: accessToken } },
      );
      dispatch(closeModal());
      dispatch(fetchInternalClientUsers());
    } catch (error) {
      dispatch(hideLoader());
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        `Error occurred while updating internal client user details due to error '${error.message}'`;
      showErrorMessage(message);
    }
  };

const toggleStatus = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  const {
    internalClientUser: { id: internalClientUserId },
  } = getState().internalClientUsers;
  dispatch(showLoader());
  try {
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${baseAPIURL}/${internalClientUserId}/toggleStatus`,
      null,
      { params: { access_token: accessToken } },
    );
    dispatch(fetchInternalClientUsers());
  } catch (error) {
    dispatch(hideLoader());
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while updating internal client user status due to error '${error.message}'`;
    showErrorMessage(message);
  }
};

export {
  setInternalClientUsers,
  fetchInternalClientUsers,
  openModal,
  closeModal,
  setInternalClientUser,
  updateInternalClientUserDetails,
  toggleStatus,
};
