import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './components/Routes';
import { fetchAdminUserDetails, setLoginDetails } from './lib/actions/common';
import Loader from './components/Loader';
import Login from './components/Login';
import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  USER_ID_LOCAL_STORAGE_KEY,
} from './lib/constants';

const App = () => {
  const {
    fetching,
    loginDetails: { id: accessToken, userId },
  } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const isLoggedIn = accessToken && userId;
  useEffect(() => {
    if (!isLoggedIn) {
      // render login if no token and id found in localstorage
      const accessTokenFromLocal = window.localStorage.getItem(
        ACCESS_TOKEN_LOCAL_STORAGE_KEY,
      );
      const userIdFromLocal = window.localStorage.getItem(
        USER_ID_LOCAL_STORAGE_KEY,
      );
      if (accessTokenFromLocal && userIdFromLocal) {
        dispatch(
          setLoginDetails({
            id: accessTokenFromLocal,
            userId: userIdFromLocal,
          }),
        );
        dispatch(fetchAdminUserDetails());
      }
    }
  }, []);

  return (
    <Router>
      {fetching && <Loader />}
      {isLoggedIn ? <Routes /> : <Login />}
    </Router>
  );
};

export default App;
