import axios from 'axios';

import {
  CLOSE_CLIENT_MODAL,
  OPEN_CLIENT_MODAL,
  SET_CLIENT,
  SET_CLIENTS,
} from './constants';
import { hideLoader, showErrorMessage, showLoader } from './common';

const setClients = (clients) => ({
  type: SET_CLIENTS,
  payload: clients,
});

const openModal = () => ({
  type: OPEN_CLIENT_MODAL,
});

const closeModal = () => ({
  type: CLOSE_CLIENT_MODAL,
});

const setClient = (client) => ({
  type: SET_CLIENT,
  payload: client,
});

const fetchClients = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  dispatch(showLoader());
  try {
    const { data: clients } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/clients`,
      { params: { access_token: accessToken } },
    );
    dispatch(setClients(clients));
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while fetching clients due to error '${error.message}'`;
    showErrorMessage(message);
  }
  dispatch(hideLoader());
};

const updateClientDetails =
  ({ name, companyName }) =>
  async (dispatch, getState) => {
    const {
      loginDetails: { id: accessToken },
    } = getState().common;
    const {
      client: { id: clientId },
    } = getState().clients;
    dispatch(showLoader());
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/clients/${clientId}`,
        { name, companyName },
        { params: { access_token: accessToken } },
      );
      dispatch(closeModal());
      dispatch(fetchClients());
    } catch (error) {
      dispatch(hideLoader());
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        `Error occurred while updating client details due to error '${error.message}'`;
      showErrorMessage(message);
    }
  };

const toggleStatus = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  const {
    client: { id: clientId },
  } = getState().clients;
  dispatch(showLoader());
  try {
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/clients/${clientId}/toggleStatus`,
      null,
      { params: { access_token: accessToken } },
    );
    dispatch(fetchClients());
  } catch (error) {
    dispatch(hideLoader());
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while updating client status due to error '${error.message}'`;
    showErrorMessage(message);
  }
};

const extendTrial = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  const {
    client: { id: clientId },
  } = getState().clients;
  dispatch(showLoader());
  try {
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/clients/${clientId}/extendTrial`,
      null,
      { params: { access_token: accessToken } },
    );
    dispatch(fetchClients());
  } catch (error) {
    dispatch(hideLoader());
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while extending client trial period due to error '${error.message}'`;
    showErrorMessage(message);
  }
};

export {
  setClients,
  fetchClients,
  openModal,
  closeModal,
  setClient,
  updateClientDetails,
  toggleStatus,
  extendTrial,
};
