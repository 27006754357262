/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

const CustomFieldCheckbox = ({
  input: { value, name, ...restInputProps },
  label,
  meta: { form },
  ...restProps
}) => (
  <>
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        id={`customSwitchesChecked${form}${name}`}
        checked={value}
        {...restInputProps}
        {...restProps}
      />
      <label
        className="custom-control-label"
        htmlFor={`customSwitchesChecked${form}${name}`}
      >
        {label}
      </label>
    </div>
  </>
);

export default CustomFieldCheckbox;

CustomFieldCheckbox.propTypes = {
  input: PropTypes.objectOf(PropTypes.object).isRequired,
  meta: PropTypes.objectOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
};
