import axios from 'axios';
import store from './store';
import { resetCommonDetails } from './actions/common';

axios.interceptors.response.use(null, (error) => {
  const { dispatch } = store;
  // logout the user if status is 'Unauthorized'
  if (error.response && error.response.status === 401) {
    dispatch(resetCommonDetails());
    window.location.assign('/');
  }
  return Promise.reject(error);
});
