import React from 'react';
import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from 'mdbreact';
import { Field, propTypes, reduxForm } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';

import { closeModal, updateSupplierDetails } from '../lib/actions/suppliers';
import CustomField, { CustomTextareaField } from './CustomField';
import { isPhoneNumber, isRequired } from '../lib/formValidations';
import CustomFieldCheckbox from './CustomFieldCheckbox';

const formName = 'supplier-form';

const EditSupplierModal = ({ handleSubmit, reset, pristine }) => {
  const dispatch = useDispatch();
  const { isModalOpen } = useSelector((state) => state.suppliers);

  return (
    <MDBModal
      inline={false}
      noClickableBodyWithoutBackdrop
      overflowScroll
      isOpen={isModalOpen}
      toggle={() => dispatch(closeModal())}
      size="lg"
    >
      <MDBModalHeader>View/Edit Supplier</MDBModalHeader>
      <form
        onSubmit={handleSubmit(({ name, address, phoneNumber }) => {
          dispatch(updateSupplierDetails({ name, address, phoneNumber }));
        })}
      >
        <MDBModalBody>
          <MDBRow>
            <MDBCol lg="4">
              <Field
                label="Name"
                component={CustomField}
                name="name"
                validate={isRequired}
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                label="Address"
                component={CustomField}
                name="address"
                validate={isRequired}
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                label="Mobile"
                component={CustomField}
                name="phoneNumber"
                validate={[isRequired, isPhoneNumber]}
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                style={{"resize": "none"}}
                label="Email"
                component={CustomTextareaField}
                name="emailID"
                disabled
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                label="Username"
                component={CustomField}
                name="username"
                disabled
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                label="KPI Status"
                component={CustomField}
                name="kpiStatus"
                disabled
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                label="Created"
                component={CustomField}
                name="createdAt"
                disabled
                type="datetime"
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                label="Last Updated"
                component={CustomField}
                name="updatedAt"
                disabled
                type="datetime"
              />
            </MDBCol>
            <MDBCol lg="4">
              <Field
                label="Last Login"
                component={CustomField}
                name="lastLogin"
                disabled
                type="datetime"
              />
            </MDBCol>
            <MDBCol lg="4"/>
            <MDBCol lg="4">
              <Field
                label="Report Start Date"
                component={CustomField}
                name="reportStartDate"
                disabled
                type="datetime"
              />
            </MDBCol>
            <MDBCol lg="12">
              <MDBRow>
                <MDBCol lg="3">
                  <Field
                    label="Status"
                    component={CustomFieldCheckbox}
                    name="isActive"
                    disabled
                  />
                </MDBCol>
                <MDBCol lg="3">
                  <Field
                    label="Email Delivery Failed"
                    component={CustomFieldCheckbox}
                    name="hasFailedDelivery"
                    disabled
                  />
                </MDBCol>
                <MDBCol lg="3">
                  <Field
                    label="Notifications"
                    component={CustomFieldCheckbox}
                    name="enableNotification"
                    disabled
                  />
                </MDBCol>
                <MDBCol lg="3">
                  <Field
                    label="Email Verified"
                    component={CustomFieldCheckbox}
                    name="emailVerified"
                    disabled
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="secondary"
            onClick={() => {
              dispatch(reset());
              dispatch(closeModal());
            }}
          >
            Close
          </MDBBtn>
          <MDBBtn type="submit" color="primary" disabled={pristine}>
            Save changes
          </MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBModal>
  );
};

const EditClientModalReduxForm = reduxForm({ form: formName })(
  EditSupplierModal,
);

export default connect((state) => ({
  initialValues: state.suppliers.supplier,
  enableReinitialize: true,
}))(EditClientModalReduxForm);

EditSupplierModal.propTypes = {
  ...propTypes,
};
