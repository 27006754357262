import React, { useEffect, useRef  } from 'react';
import { MDBCol, MDBRow, MDBTypography } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import Layout from '../Layout';
import {
  fetchSuppliers,
  openModal,
  setSupplier,
  toggleStatus,
} from '../../lib/actions/suppliers';
import { filteredDataDownload } from '../../lib/actions/common';
import EditSupplierModal from '../EditSupplierModal';

const Suppliers = () => {
  const dispatch = useDispatch();
  const filteredUsersIds = useRef([]);

  useEffect(() => {
    dispatch(fetchSuppliers());
  }, []);

  const { suppliers = [] } = useSelector((state) => state.suppliers);

  const downloadCsvHandler = () => {
    console.log('downloadCsvHandler');
    let userIdsForCSV;
    if(filteredUsersIds.current && filteredUsersIds.current.length) {
      userIdsForCSV = filteredUsersIds.current;
    } else {
      userIdsForCSV = suppliers.map(supplierUserItem => supplierUserItem.id);
    }
    console.log('userIdsForCSV:', userIdsForCSV);
    dispatch(filteredDataDownload(userIdsForCSV, "supplier"));
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        hint: 'Click on a specific supplier name to View/Edit the supplier details',
        customBodyRender: (value, { rowIndex, currentTableData }) => (
          <div
            className="cursor-pointer"
            onClick={() => {
              const selectedSupplier =
                suppliers[currentTableData[rowIndex].index];
              dispatch(setSupplier(selectedSupplier));
              dispatch(openModal());
            }}
            onKeyPress={() => {
              const selectedSupplier =
                suppliers[currentTableData[rowIndex].index];
              dispatch(setSupplier(selectedSupplier));
              dispatch(openModal());
            }}
            tabIndex="0"
            role="button"
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: 'emailID',
      label: 'Email',
    },
    {
      name: 'username',
      label: 'Username',
    },
    {
      name: 'address',
      label: 'Address',
    },
    {
      name: 'phoneNumber',
      label: 'Mobile',
    },
    {
      name: 'kpiStatus',
      label: 'KPI Status',
    },
    {
      name: 'isActive',
      label: 'Access',
      options: {
        customBodyRender: (value, { rowIndex, currentTableData }) => (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`customSwitchesChecked${rowIndex}`}
              checked={value}
              onChange={() => {
                const selectedSupplier =
                  suppliers[currentTableData[rowIndex].index];
                dispatch(setSupplier(selectedSupplier));
                dispatch(toggleStatus());
              }}
            />
            <label
              className="custom-control-label cursor-pointer"
              htmlFor={`customSwitchesChecked${rowIndex}`}
            />
          </div>
        ),
      },
    },
    {
      name: 'isNewUser',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'emailVerified',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'enableNotification',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'hasFailedDelivery',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'forgotPassword',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'lastLogin',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'reportStartDate',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'createdAt',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'updatedAt',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
  ];

  const dataTableOptions = {
    selectableRows: 'none',
    download: true,
    onFilterChange:(_1, _2, _3, _4, data) => {
      const userIds = data.map(item=>item.data[0]);
      filteredUsersIds.current = userIds;
    }
  };

   // Creating custom CSV button
   useEffect(()=>{
    if(suppliers && suppliers.length){

      const downloadCsvEle = document.querySelector('[data-testid="DownloadCSV-iconButton"]');
      const x = downloadCsvEle.outerHTML;
      downloadCsvEle.remove();
      const downloadCsvPortalElement = document.createElement('div');
      downloadCsvPortalElement.setAttribute('id','download-csv-portal');
      downloadCsvPortalElement.style.display = 'inline-block';
      document.querySelector('[data-testid="Search-iconButton"]').after(downloadCsvPortalElement);
      downloadCsvPortalElement.innerHTML= x;
      document.querySelector('[data-testid="DownloadCSV-iconButton"]').addEventListener('click', downloadCsvHandler);
      // downloadCsvPortalElement
    }
  },[suppliers])

  return (
    <Layout title="Suppliers">
      <EditSupplierModal />
      <MDBRow>
        <MDBCol lg="12">
          {suppliers.length !== 0 && (
            <MUIDataTable
              title="List of Suppliers"
              data={suppliers}
              options={dataTableOptions}
              columns={columns}
            />
          )}
          {suppliers.length === 0 && (
            <div className="text-center">
              <MDBTypography variant="h4">
                No Supplier Users Found
              </MDBTypography>
            </div>
          )}
        </MDBCol>
      </MDBRow>
    </Layout>
  );
};

export default Suppliers;
