/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

const CustomField = ({
  input: { name, ...inputProps },
  meta: { touched, error, form },
  label,
  ...restProps
}) => (
  <>
    <div className="form-group">
      <label htmlFor={`customFieldInput${name}${form}`}>{label}</label>
      <input
        name={name}
        className="form-control"
        id={`customFieldInput${name}${form}`}
        {...inputProps}
        {...restProps}
      />
      {touched && error && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </div>
  </>
);

const CustomTextareaField = ({
  input: { name, ...inputProps },
  meta: { touched, error, form },
  label,
  ...restProps
}) => (
  <>
    <div className="form-group">
      <label htmlFor={`customFieldInput${name}${form}`}>{label}</label>
      <textarea
        name={name}
        className="form-control"
        id={`customFieldInput${name}${form}`}
        {...inputProps}
        {...restProps}
      />
      {touched && error && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </div>
  </>
);

export default CustomField;
export { CustomTextareaField };

CustomField.defaultProps = {
  label: '',
};

CustomField.propTypes = {
  input: PropTypes.objectOf(PropTypes.object).isRequired,
  meta: PropTypes.objectOf(PropTypes.object).isRequired,
  label: PropTypes.string,
};
