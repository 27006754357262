import React from 'react';
import { DotLoader } from 'react-spinners';

const Loader = () => (
  <div className="loader">
    <DotLoader loading color="#002B64" size={60} />
  </div>
);

export default Loader;
