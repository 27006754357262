import validator from 'validator';

const isStrongPassword = (password) =>
  password && password.length < 7
    ? 'Password should contain at least 7 characters'
    : undefined;

const isTemporaryPassword = (password) =>
  password && password.length !== 16
    ? 'Temporary Password should contain 16 characters'
    : undefined;

const isPasswordSame = (_, allValues) =>
  allValues.newPassword !== allValues.confirmNewPassword
    ? "Password's don't match"
    : undefined;

const isRequired = (value) =>
  !value || !value.trim() ? 'This field is required' : undefined;

const isEmail = (email) =>
  email && !validator.isEmail(email)
    ? 'Please provide a valid email'
    : undefined;

const isPhoneNumber = (phoneNumber) =>
  phoneNumber && !validator.isMobilePhone(phoneNumber)
    ? 'Please provide a valid phone number'
    : undefined;

const isValidTarget = (target) =>
  !target || target < 1 || target > 100
    ? 'Target should be between 1 and 100'
    : undefined;

export {
  isStrongPassword,
  isTemporaryPassword,
  isPasswordSame,
  isRequired,
  isEmail,
  isPhoneNumber,
  isValidTarget,
};
