import {
  CLOSE_CLIENT_MODAL,
  OPEN_CLIENT_MODAL, SET_CLIENT,
  SET_CLIENTS,
  FILTERED_DATA,
} from '../actions/constants';

const initialState = {
  clients: [],
  isModalOpen: false,
  client:{},
  filteredClientId: [],
};



const clients = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CLIENTS:
      return {
        ...state,
        clients: payload,
      };
    case OPEN_CLIENT_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };
    case CLOSE_CLIENT_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };
    case SET_CLIENT:
      return {
        ...state,
        client: payload,
      };
    case FILTERED_DATA:
      return {
        ...state,
        filteredClientId: payload
      }
    default:
      return state;
  }
};

export default clients;
