import axios from 'axios';

import {
  CLOSE_INTERNAL_SUPPLIER_USER_MODAL,
  OPEN_INTERNAL_SUPPLIER_USER_MODAL,
  SET_INTERNAL_SUPPLIER_USER,
  SET_INTERNAL_SUPPLIER_USERS,
} from './constants';
import { hideLoader, showErrorMessage, showLoader } from './common';

const baseAPIURL = '/internalSupplierUsers';

const setInternalSupplierUsers = (internalSupplierUsers) => ({
  type: SET_INTERNAL_SUPPLIER_USERS,
  payload: internalSupplierUsers,
});

const openModal = () => ({
  type: OPEN_INTERNAL_SUPPLIER_USER_MODAL,
});

const closeModal = () => ({
  type: CLOSE_INTERNAL_SUPPLIER_USER_MODAL,
});

const setInternalSupplierUser = (internalSupplierUser) => ({
  type: SET_INTERNAL_SUPPLIER_USER,
  payload: internalSupplierUser,
});

const fetchInternalSupplierUsers = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  dispatch(showLoader());
  try {
    const { data: internalSupplierUsers } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}${baseAPIURL}`,
      { params: { access_token: accessToken } },
    );
    dispatch(setInternalSupplierUsers(internalSupplierUsers));
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while fetching internal supplier users due to error '${error.message}'`;
    showErrorMessage(message);
  }
  dispatch(hideLoader());
};

const updateInternalSupplierUserDetails =
  ({ name }) =>
  async (dispatch, getState) => {
    const {
      loginDetails: { id: accessToken },
    } = getState().common;
    const {
      internalSupplierUser: { id: internalSupplierUserId },
    } = getState().internalSupplierUsers;
    dispatch(showLoader());
    try {
      await axios.patch(
        `${process.REACT_APP_API_BASE_URL}${baseAPIURL}/${internalSupplierUserId}`,
        { name },
        { params: { access_token: accessToken } },
      );
      dispatch(closeModal());
      dispatch(fetchInternalSupplierUsers());
    } catch (error) {
      dispatch(hideLoader());
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        `Error occurred while updating internal supplier user details due to error '${error.message}'`;
      showErrorMessage(message);
    }
  };

const toggleStatus = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  const {
    internalSupplierUser: { id: internalSupplierUserId },
  } = getState().internalSupplierUsers;
  dispatch(showLoader());
  try {
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${baseAPIURL}/${internalSupplierUserId}/toggleStatus`,
      null,
      { params: { access_token: accessToken } },
    );
    dispatch(fetchInternalSupplierUsers());
  } catch (error) {
    dispatch(hideLoader());
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while updating internal supplier user status due to error '${error.message}'`;
    showErrorMessage(message);
  }
};

export {
  setInternalSupplierUsers,
  fetchInternalSupplierUsers,
  openModal,
  closeModal,
  setInternalSupplierUser,
  updateInternalSupplierUserDetails,
  toggleStatus,
};
