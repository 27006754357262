import axios from 'axios';

import {
  CLOSE_CREATE_ADMIN_MODAL,
  CLOSE_SET_PASSWORD_ADMIN_MODAL,
  OPEN_CREATE_ADMIN_MODAL,
  OPEN_SET_PASSWORD_ADMIN_MODAL,
  SET_ADMIN,
  SET_ADMINS,
} from './constants';
import { hideLoader, showErrorMessage, showLoader } from './common';

const setAdmins = (admins) => ({
  type: SET_ADMINS,
  payload: admins,
});

const openCreateAdminModal = () => ({
  type: OPEN_CREATE_ADMIN_MODAL,
});

const closeCreateAdminModal = () => ({
  type: CLOSE_CREATE_ADMIN_MODAL,
});

const openSetPasswordModal = () => ({
  type: OPEN_SET_PASSWORD_ADMIN_MODAL,
});

const closeSetPasswordModal = () => ({
  type: CLOSE_SET_PASSWORD_ADMIN_MODAL,
});

const setAdmin = (admin) => ({
  type: SET_ADMIN,
  payload: admin,
});



const fetchAdmins = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  dispatch(showLoader());
  try {
    const { data: admins } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/admins`,
      { params: { access_token: accessToken } },
    );
    dispatch(setAdmins(admins));
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while fetching admins due to error '${error.message}'`;
    showErrorMessage(message);
  }
  dispatch(hideLoader());
};

const setAdminPassword =
  ({ newPassword }) =>
  async (dispatch, getState) => {
    const {
      loginDetails: { id: accessToken },
    } = getState().common;
    const {
      admin: { id: adminId },
    } = getState().admins;
    dispatch(showLoader());
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admins/${adminId}/setPassword`,
        { password: newPassword },
        { params: { access_token: accessToken } },
      );
      dispatch(closeSetPasswordModal());
      dispatch(fetchAdmins());
    } catch (error) {
      dispatch(hideLoader());
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        `Error occurred while setting admin password due to error '${error.message}'`;
      showErrorMessage(message);
    }
  };

const createAdminUser =
  ({ email, username, password }) =>
  async (dispatch, getState) => {
    const {
      loginDetails: { id: accessToken },
    } = getState().common;
    dispatch(showLoader());
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admins/`,
        { email, username, password },
        { params: { access_token: accessToken } },
      );
      dispatch(closeCreateAdminModal());
      dispatch(fetchAdmins());
    } catch (error) {
      dispatch(hideLoader());
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        `Error occurred while creating admin user due to error '${error.message}'`;
      showErrorMessage(message);
    }
  };

const toggleStatus = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  const {
    admin: { id: adminId },
  } = getState().admins;
  dispatch(showLoader());
  try {
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/admins/${adminId}/toggleStatus`,
      null,
      { params: { access_token: accessToken } },
    );
    dispatch(fetchAdmins());
  } catch (error) {
    dispatch(hideLoader());
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while updating admin status due to error '${error.message}'`;
    showErrorMessage(message);
  }
};

export {
  setAdmins,
  setAdmin,
  openCreateAdminModal,
  closeCreateAdminModal,
  fetchAdmins,
  setAdminPassword,
  closeSetPasswordModal,
  openSetPasswordModal,
  toggleStatus,
  createAdminUser,
};
