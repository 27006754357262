import {
  CLOSE_CREATE_ADMIN_MODAL,
  CLOSE_SET_PASSWORD_ADMIN_MODAL,
  OPEN_CREATE_ADMIN_MODAL,
  OPEN_SET_PASSWORD_ADMIN_MODAL,
  SET_ADMIN,
  SET_ADMINS,
} from '../actions/constants';

const initialState = {
  admins: [],
  isCreateModalOpen: false,
  isPasswordModalOpen: false,
  admin: {},
};

const admins = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ADMINS:
      return {
        ...state,
        admins: payload,
      };
    case OPEN_CREATE_ADMIN_MODAL:
      return {
        ...state,
        isCreateModalOpen: true,
      };
    case CLOSE_CREATE_ADMIN_MODAL:
      return {
        ...state,
        isCreateModalOpen: false,
      };
    case OPEN_SET_PASSWORD_ADMIN_MODAL:
      return {
        ...state,
        isPasswordModalOpen: true,
      };
    case CLOSE_SET_PASSWORD_ADMIN_MODAL:
      return {
        ...state,
        isPasswordModalOpen: false,
      };
    case SET_ADMIN:
      return {
        ...state,
        admin: payload,
      };
    default:
      return state;
  }
};

export default admins;
