import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { MDBCol, MDBRow, MDBTypography } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import Layout from '../Layout';
import {
  fetchClients,
  openModal,
  setClient,
  toggleStatus,
} from '../../lib/actions/clients';
import { filteredDataDownload } from '../../lib/actions/common';
import EditClientModal from '../EditClientModal';

const Clients = () => {
  const dispatch = useDispatch();
  const filteredUsersIds = useRef([]);

  const { clients = [] } = useSelector((state) => state.clients);
  

  const downloadCsvHandler = () => {
    console.log('downloadCsvHandler');
    let userIdsForCSV;
    if(filteredUsersIds.current && filteredUsersIds.current.length) {
      userIdsForCSV = filteredUsersIds.current;
    } else {
      userIdsForCSV = clients.map(clientUserItem=>clientUserItem.id);
    }
    console.log('userIdsForCSV:', userIdsForCSV);
    dispatch(filteredDataDownload(userIdsForCSV, "client"));
  }

  useEffect(() => {
    dispatch(fetchClients());
    console.log("dfc", clients);
  }, []);

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        hint: 'Click on a specific client name to View/Edit the client details',
        customBodyRender: (value, { rowIndex, currentTableData }) => (
          <div
            className="cursor-pointer"
            onClick={() => {
              const selectedClient = clients[currentTableData[rowIndex].index];
              dispatch(setClient(selectedClient));
              dispatch(openModal());
            }}
            onKeyPress={() => {
              const selectedClient = clients[currentTableData[rowIndex].index];
              dispatch(setClient(selectedClient));
              dispatch(openModal());
            }}
            tabIndex="0"
            role="button"
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'username',
      label: 'Username',
    },
    {
      name: 'plan',
      label: 'Plan',
    },
    {
      name: 'companyName',
      label: 'Company Name',
    },
    {
      name: 'isActive',
      label: 'Access',
      options: {
        customBodyRender: (value, { rowIndex, currentTableData }) => (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`customSwitchesChecked${rowIndex}`}
              checked={value}
              onChange={() => {
                const selectedClient =
                  clients[currentTableData[rowIndex].index];
                dispatch(setClient(selectedClient));
                dispatch(toggleStatus());
              }}
            />
            <label
              className="custom-control-label cursor-pointer"
              htmlFor={`customSwitchesChecked${rowIndex}`}
            />
          </div>
        ),
      },
    },
    {
      name: 'stripeCustomerID',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'emailVerified',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'enableNotification',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'hasFailedDelivery',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'forgotPassword',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'lastLogin',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'createdAt',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'updatedAt',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
  ];

  const dataTableOptions = {
    selectableRows: 'none',
    download: true,
    onFilterChange:(_1, _2, _3, _4, data) => {
      const userIds = data.map(item=>item.data[0]);
      filteredUsersIds.current = userIds;
    }
  };

  // Creating custom CSV button
  useEffect(()=>{
    if(clients && clients.length){

      const downloadCsvEle = document.querySelector('[data-testid="DownloadCSV-iconButton"]');
      const x = downloadCsvEle.outerHTML;
      downloadCsvEle.remove();
      const downloadCsvPortalElement = document.createElement('div');
      downloadCsvPortalElement.setAttribute('id','download-csv-portal');
      downloadCsvPortalElement.style.display = 'inline-block';
      document.querySelector('[data-testid="Search-iconButton"]').after(downloadCsvPortalElement);
      downloadCsvPortalElement.innerHTML= x;
      document.querySelector('[data-testid="DownloadCSV-iconButton"]').addEventListener('click', downloadCsvHandler);
      // downloadCsvPortalElement
    }
  },[clients])

  return (
    <Layout title="Clients">
      <EditClientModal />
      <MDBRow>
        <MDBCol lg="12">
          {clients.length !== 0 && (
            <MUIDataTable
              title="List of Clients"
              data={clients}
              columns={columns}
              options={dataTableOptions}
            />
          )}
          {clients.length === 0 && (
            <div className="text-center">
              <MDBTypography variant="h4">No Client Users Found</MDBTypography>
            </div>
          )}
        </MDBCol>
      </MDBRow>
    </Layout>
  );
};

export default Clients;
