import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { showErrorMessage } from '../lib/actions/common';

const SuperAdminRoute = (props) => {
  const {
    adminUserDetails: { username },
  } = useSelector((state) => state.common);
  // redirect the admin user to home page if not super admin
  if (username !== process.env.REACT_APP_SUPER_ADMIN_USERNAME) {
    showErrorMessage('Log in as super admin to access this link');
    return <Redirect to="/" />;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...props} />;
};

export default SuperAdminRoute;
