import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import Logo from '../../assets/logo.png';
import NotFoundLogo from '../../assets/not-found.png';
import Layout from '../Layout';

const NotFound = () => (
  <Layout>
    <div className="full">
      <MDBRow className="bad-gateway-row">
        <MDBCol md="8">
          <img alt="Error 404" className="img-fluid" height="20px" src={Logo} />
          <h2 className="h2-responsive mt-3 mb-2">
            404. That &apos;s an error.
          </h2>
          <h4>The requested URL was not found on this server.</h4>
        </MDBCol>
        <MDBCol md="4">
          <img alt="Error 404" className="img-fluid" src={NotFoundLogo} />
        </MDBCol>
      </MDBRow>
    </div>
  </Layout>
);

export default NotFound;
