import React from 'react';
import {
  MDBCollapse,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBNavItem,
  MDBTypography,
} from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { logout, toggleHeader } from '../lib/actions/common';

const Header = ({ title }) => {
  const dispatch = useDispatch();
  const { isHeaderCollapsed } = useSelector((state) => state.common);

  return (
    <MDBNavbar className="flexible-navbar" light expand="md" scrolling>
      <MDBNavbarToggler onClick={() => dispatch(toggleHeader())} />
      <MDBCollapse isOpen={isHeaderCollapsed} navbar className="ml-3">
        <MDBNavbarNav left>
          <MDBNavItem>
            <MDBTypography variant="h2">{title}</MDBTypography>
          </MDBNavItem>
        </MDBNavbarNav>
        <MDBNavbarNav right>
          <MDBNavItem>
            <MDBDropdown>
              <MDBDropdownToggle nav caret>
                <MDBIcon icon="user" />
              </MDBDropdownToggle>
              <MDBDropdownMenu right>
                <MDBDropdownItem
                  onClick={() => {
                    dispatch(logout());
                  }}
                >
                  Logout
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavItem>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  );
};

export default Header;

Header.propTypes = {
  title: PropTypes.string.isRequired,
};
