import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { MDBCol, MDBRow, MDBTypography } from 'mdbreact';

import Layout from '../Layout';
import SetGlobalPiModal from '../SetGlobalPIModal';
import {
  fetchCustomPIs,
  openSetGlobalPIModal,
  setCustomPI,
} from '../../lib/actions/customPIs';

const CustomPIs = () => {
  const dispatch = useDispatch();

  const { customPIs = [] } = useSelector((state) => state.customPIs);

  useEffect(() => {
    dispatch(fetchCustomPIs());
  }, []);

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        hint: 'Click on a specific pi name to make it global',
        customBodyRender: (value, { rowIndex, currentTableData }) => (
          <div
            className="cursor-pointer"
            onClick={() => {
              const selectedPI = customPIs[currentTableData[rowIndex].index];
              dispatch(setCustomPI(selectedPI));
              dispatch(openSetGlobalPIModal());
            }}
            onKeyPress={() => {
              const selectedPI = customPIs[currentTableData[rowIndex].index];
              dispatch(setCustomPI(selectedPI));
              dispatch(openSetGlobalPIModal());
            }}
            tabIndex="0"
            role="button"
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: 'methodology',
      label: 'Methodology',
    },
    {
      name: 'frequency',
      label: 'Frequency',
    },
    {
      name: 'target',
      label: 'Target',
    },
    {
      name: 'category',
      label: 'Category',
    },
    {
      name: 'isActive',
      label: 'Status',
      options: {
        customBodyRender: (value) => (value ? 'Yes' : 'No'),
      },
    },
  ];

  const dataTableOptions = {
    selectableRows: 'none',
  };

  return (
    <Layout title="Custom PIs">
      <SetGlobalPiModal />
      <MDBRow>
        <MDBCol lg="12">
          {customPIs.length !== 0 && (
            <MUIDataTable
              title="List of Custom PIs"
              data={customPIs}
              columns={columns}
              options={dataTableOptions}
            />
          )}
          {customPIs.length === 0 && (
            <div className="text-center">
              <MDBTypography variant="h4">No Custom PIs Found</MDBTypography>
            </div>
          )}
        </MDBCol>
      </MDBRow>
    </Layout>
  );
};

export default CustomPIs;
