import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from 'mdbreact';
import { Field, propTypes, reduxForm } from 'redux-form';

import { closeSetPasswordModal, setAdminPassword } from '../lib/actions/admins';
import CustomField from './CustomField';
import {
  isPasswordSame,
  isRequired,
  isStrongPassword,
} from '../lib/formValidations';

const formName = 'set-password-admin-form';

const SetAdminPasswordModal = ({ handleSubmit, reset, pristine }) => {
  const dispatch = useDispatch();
  const { isPasswordModalOpen } = useSelector((state) => state.admins);

  useEffect(() => {
    // close after setting admin password
    if (!isPasswordModalOpen) {
      dispatch(reset());
    }
  }, [isPasswordModalOpen]);

  return (
    <MDBModal
      inline={false}
      noClickableBodyWithoutBackdrop
      overflowScroll
      isOpen={isPasswordModalOpen}
      toggle={() => dispatch(closeSetPasswordModal())}
      size="md"
    >
      <MDBModalHeader>Set Admin Password</MDBModalHeader>
      <form
        onSubmit={handleSubmit(({ newPassword }) => {
          dispatch(setAdminPassword({ newPassword }));
        })}
      >
        <MDBModalBody>
          <MDBRow>
            <MDBCol lg="6">
              <Field
                label="New Password"
                component={CustomField}
                name="newPassword"
                validate={[isRequired, isStrongPassword]}
              />
            </MDBCol>
            <MDBCol lg="6">
              <Field
                label="Confirm New Password"
                component={CustomField}
                name="confirmNewPassword"
                validate={[isRequired, isStrongPassword, isPasswordSame]}
              />
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="secondary"
            onClick={() => {
              dispatch(reset());
              dispatch(closeSetPasswordModal());
            }}
          >
            Close
          </MDBBtn>
          <MDBBtn type="submit" color="primary" disabled={pristine}>
            Set Password
          </MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBModal>
  );
};

export default reduxForm({ form: formName })(SetAdminPasswordModal);

SetAdminPasswordModal.propTypes = {
  ...propTypes,
};
