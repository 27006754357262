import axios from 'axios';

import {
  SET_CURRENT_PAGE_OF_PAYMENTS,
  SET_PAYMENTS,
  SET_ROWS_PER_PAGE_OF_PAYMENTS,
  SET_TOTAL_COUNT_OF_PAYMENTS,
} from './constants';
import { hideLoader, showErrorMessage, showLoader } from './common';

const setPayments = (payments) => ({
  type: SET_PAYMENTS,
  payload: payments,
});

const setTotalCount = (totalCount) => ({
  type: SET_TOTAL_COUNT_OF_PAYMENTS,
  payload: totalCount,
});

const setRowsPerPage = (rowsPerPage) => ({
  type: SET_ROWS_PER_PAGE_OF_PAYMENTS,
  payload: rowsPerPage,
});

const setCurrentPage = (currentPage) => ({
  type: SET_CURRENT_PAGE_OF_PAYMENTS,
  payload: currentPage,
});

const fetchPayments =
  (firstTransactionId, lastTransactionId, newLimit) =>
  async (dispatch, getState) => {
    const {
      loginDetails: { id: accessToken },
    } = getState().common;
    const {
      rowsPerPage,
      payments: currentPayments = [],
      currentPage,
      totalCount: currentTotalCount,
    } = getState().payments;
    dispatch(showLoader());
    try {
      const {
        data: { data: payments = [], total_count: totalCount },
      } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/admins/stripePayments`,
        {
          params: {
            access_token: accessToken,
            limit: newLimit || rowsPerPage,
            firstTransactionId,
            lastTransactionId,
          },
        },
      );
      let newPayments;
      // case where number of records have changed so reassign the array
      if (newLimit) {
        newPayments = payments;
      } else {
        newPayments = Array.from({
          length: rowsPerPage * (currentPage + 1),
        }).map((_, index) => {
          if (
            index >= rowsPerPage * currentPage &&
            index < rowsPerPage * (currentPage + 1)
          ) {
            const paymentIndex = index - rowsPerPage * currentPage;
            return payments[paymentIndex];
          }
          return currentPayments[index];
        });
      }
      dispatch(setPayments(newPayments));
      // check whether total number of records have changed
      if (currentTotalCount && totalCount > currentTotalCount) {
        dispatch(
          fetchPayments(
            null,
            null,
            newPayments.length + (totalCount - currentTotalCount),
          ),
        );
      } else {
        dispatch(hideLoader());
      }
      dispatch(setTotalCount(totalCount));
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        `Error occurred while fetching stripe payments due to error '${error.message}'`;
      showErrorMessage(message);
      dispatch(hideLoader());
    }
  };

export {
  setPayments,
  fetchPayments,
  setTotalCount,
  setRowsPerPage,
  setCurrentPage,
};
