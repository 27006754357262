// Start of constants for common reducer
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SET_LOGIN_DETAILS = 'SET_LOGIN_DETAILS';
export const SET_ADMIN_USER_DETAILS = 'SET_ADMIN_USER_DETAILS';
export const RESET_COMMON_DETAILS = 'RESET_COMMON_DETAILS';
export const TOGGLE_HEADER = 'TOGGLE_HEADER';
export const FILTERED_DATA = 'FILTERED_DATA';
// End of constants for common reducer

// Start of constants for clients reducer
export const SET_CLIENTS = 'SET_CLIENTS';
export const OPEN_CLIENT_MODAL = 'OPEN_CLIENT_MODAL';
export const CLOSE_CLIENT_MODAL = 'CLOSE_CLIENT_MODAL';
export const SET_CLIENT = 'SET_CLIENT';
// End of constants for clients reducer

// Start of constants for suppliers reducer
export const SET_SUPPLIERS = 'SET_SUPPLIERS';
export const OPEN_SUPPLIER_MODAL = 'OPEN_SUPPLIER_MODAL';
export const CLOSE_SUPPLIER_MODAL = 'CLOSE_SUPPLIER_MODAL';
export const SET_SUPPLIER = 'SET_SUPPLIER';
// End of constants for suppliers reducer

// Start of constants for internal client users reducer
export const SET_INTERNAL_CLIENT_USERS = 'SET_INTERNAL_CLIENT_USERS';
export const OPEN_INTERNAL_CLIENT_USER_MODAL =
  'OPEN_INTERNAL_CLIENT_USER_MODAL';
export const CLOSE_INTERNAL_CLIENT_USER_MODAL =
  'CLOSE_INTERNAL_CLIENT_USER_MODAL';
export const SET_INTERNAL_CLIENT_USER = 'SET_INTERNAL_CLIENT_USER';
// End of constants for internal client users reducer

// Start of constants for internal supplier users reducer
export const SET_INTERNAL_SUPPLIER_USERS = 'SET_INTERNAL_SUPPLIER_USERS';
export const OPEN_INTERNAL_SUPPLIER_USER_MODAL =
  'OPEN_INTERNAL_SUPPLIER_USER_MODAL';
export const CLOSE_INTERNAL_SUPPLIER_USER_MODAL =
  'CLOSE_INTERNAL_SUPPLIER_USER_MODAL';
export const SET_INTERNAL_SUPPLIER_USER = 'SET_INTERNAL_SUPPLIER_USER';
// End of constants for internal supplier users reducer

// Start of constants for reports reducer
export const SET_REPORTS = 'SET_REPORTS';
export const SET_REPORT = 'SET_REPORT';
// End of constants for reports reducer

// Start of constants for payments reducer
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const SET_TOTAL_COUNT_OF_PAYMENTS = 'SET_TOTAL_COUNT_OF_PAYMENTS';
export const SET_ROWS_PER_PAGE_OF_PAYMENTS = 'SET_ROWS_PER_PAGE_OF_PAYMENTS';
export const SET_CURRENT_PAGE_OF_PAYMENTS = 'SET_CURRENT_PAGE_OF_PAYMENTS';
// End of constants for payments reducer

// Start of constants for admins reducer
export const SET_ADMINS = 'SET_ADMINS';
export const SET_ADMIN = 'SET_ADMIN';
export const OPEN_CREATE_ADMIN_MODAL = 'OPEN_CREATE_ADMIN_MODAL';
export const CLOSE_CREATE_ADMIN_MODAL = 'CLOSE_CREATE_ADMIN_MODAL';
export const OPEN_SET_PASSWORD_ADMIN_MODAL = 'OPEN_SET_PASSWORD_ADMIN_MODAL';
export const CLOSE_SET_PASSWORD_ADMIN_MODAL = 'CLOSE_SET_PASSWORD_ADMIN_MODAL';
// End of constants for admins reducer

// Start of constants for custom PIs reducer
export const SET_CUSTOM_PIS = 'SET_CUSTOM_PIS';
export const SET_CUSTOM_PI = 'SET_CUSTOM_PI';
export const OPEN_SET_GLOBAL_PI_MODAL = 'OPEN_SET_GLOBAL_PI_MODAL';
export const CLOSE_SET_GLOBAL_PI_MODAL = 'CLOSE_SET_GLOBAL_PI_MODAL';
// End of constants for custom PIs reducer
