import React from 'react';
import { MDBIcon, MDBListGroup, MDBListGroupItem } from 'mdbreact';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../assets/logo.png';

const TopNavigation = () => (
  <div className="sidebar-fixed position-fixed">
    <Link to="/" className="logo-wrapper waves-effect">
      <img alt="logo" className="img-fluid" src={Logo} />
    </Link>
    <MDBListGroup className="list-group-flush">
      <NavLink
        exact
        to="/clients"
        isActive={(match, location) => match || location.pathname === '/'}
        activeClassName="activeClass"
      >
        <MDBListGroupItem>
          <MDBIcon icon="user" className="mr-3" />
          Clients
        </MDBListGroupItem>
      </NavLink>
      <NavLink to="/suppliers" activeClassName="activeClass">
        <MDBListGroupItem>
          <MDBIcon icon="user" className="mr-3" />
          Suppliers
        </MDBListGroupItem>
      </NavLink>
      <NavLink to="/internalClientUsers" activeClassName="activeClass">
        <MDBListGroupItem>
          <MDBIcon icon="user" className="mr-3" />
          Internal Client Users
        </MDBListGroupItem>
      </NavLink>
      <NavLink to="/internalSupplierUsers" activeClassName="activeClass">
        <MDBListGroupItem className="list-group-item-custom">
          <MDBIcon icon="user" className="mr-3" />
          <span>Internal Supplier Users</span>
        </MDBListGroupItem>
      </NavLink>
      <NavLink to="/reports" activeClassName="activeClass">
        <MDBListGroupItem>
          <MDBIcon icon="file" className="mr-3" />
          Reports
        </MDBListGroupItem>
      </NavLink>
      <NavLink to="/payments" activeClassName="activeClass">
        <MDBListGroupItem>
          <MDBIcon icon="money-check" className="mr-3" />
          Payments
        </MDBListGroupItem>
      </NavLink>
      <NavLink to="/admins" activeClassName="activeClass">
        <MDBListGroupItem>
          <MDBIcon icon="user" className="mr-3" />
          Admins
        </MDBListGroupItem>
      </NavLink>
      <NavLink to="/customPIs" activeClassName="activeClass">
        <MDBListGroupItem>
          <MDBIcon icon="file" className="mr-3" />
          Custom PIs
        </MDBListGroupItem>
      </NavLink>
    </MDBListGroup>
  </div>
);

export default TopNavigation;
