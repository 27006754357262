import {
  SET_CURRENT_PAGE_OF_PAYMENTS,
  SET_PAYMENTS,
  SET_ROWS_PER_PAGE_OF_PAYMENTS,
  SET_TOTAL_COUNT_OF_PAYMENTS,
} from '../actions/constants';

const initialState = {
  payments: [],
  totalCount: 0,
  rowsPerPage: 10,
  currentPage: 0,
  numberOfRows: 10,
};

const payments = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PAYMENTS:
      return {
        ...state,
        payments: payload,
      };
    case SET_TOTAL_COUNT_OF_PAYMENTS:
      return {
        ...state,
        totalCount: payload,
      };
    case SET_ROWS_PER_PAGE_OF_PAYMENTS:
      return {
        ...state,
        rowsPerPage: payload,
      };
    case SET_CURRENT_PAGE_OF_PAYMENTS:
      return {
        ...state,
        currentPage: payload,
      };
    default:
      return state;
  }
};

export default payments;
