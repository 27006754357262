import {
  HIDE_LOADER,
  RESET_COMMON_DETAILS,
  SET_ADMIN_USER_DETAILS,
  SET_LOGIN_DETAILS,
  SHOW_LOADER,
  TOGGLE_HEADER,
} from '../actions/constants';

const initialState = {
  fetching: false,
  loginDetails: {},
  adminUserDetails: {},
  isHeaderCollapsed: false,
};

const common = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LOADER:
      return {
        ...state,
        fetching: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        fetching: false,
      };
    case SET_LOGIN_DETAILS:
      return {
        ...state,
        loginDetails: payload,
      };
    case SET_ADMIN_USER_DETAILS:
      return {
        ...state,
        adminUserDetails: payload,
      };
    case TOGGLE_HEADER:
      return {
        ...state,
        isHeaderCollapsed: !state.isHeaderCollapsed,
      };
    case RESET_COMMON_DETAILS:
      return initialState;
    default:
      return state;
  }
};

export default common;
