import {
  CLOSE_INTERNAL_CLIENT_USER_MODAL,
  OPEN_INTERNAL_CLIENT_USER_MODAL,
  SET_INTERNAL_CLIENT_USER,
  SET_INTERNAL_CLIENT_USERS,
} from '../actions/constants';

const initialState = {
  internalClientUsers: [],
  isModalOpen: false,
  internalClientUser: {},
};

const internalClientUsers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INTERNAL_CLIENT_USERS:
      return {
        ...state,
        internalClientUsers: payload,
      };
    case OPEN_INTERNAL_CLIENT_USER_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };
    case CLOSE_INTERNAL_CLIENT_USER_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };
    case SET_INTERNAL_CLIENT_USER:
      return {
        ...state,
        internalClientUser: payload,
      };
    default:
      return state;
  }
};

export default internalClientUsers;
