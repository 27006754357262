import axios from 'axios';

import {
  CLOSE_SET_GLOBAL_PI_MODAL,
  OPEN_SET_GLOBAL_PI_MODAL,
  SET_CUSTOM_PI,
  SET_CUSTOM_PIS,
} from './constants';
import { hideLoader, showErrorMessage, showLoader } from './common';

const setCustomPIs = (customPIs) => ({
  type: SET_CUSTOM_PIS,
  payload: customPIs,
});

const setCustomPI = (customPI) => ({
  type: SET_CUSTOM_PI,
  payload: customPI,
});

const openSetGlobalPIModal = () => ({
  type: OPEN_SET_GLOBAL_PI_MODAL,
});

const closeSetGlobalPIModal = () => ({
  type: CLOSE_SET_GLOBAL_PI_MODAL,
});

const fetchCustomPIs = () => async (dispatch, getState) => {
  const {
    loginDetails: { id: accessToken },
  } = getState().common;
  dispatch(showLoader());
  try {
    const { data: customPIs } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/pis`,
      {
        params: {
          access_token: accessToken,
          'filter[where][isUserAdded]': true,
        },
      },
    );
    dispatch(setCustomPIs(customPIs));
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.errorMessage) ||
      `Error occurred while fetching custom PIs due to error '${error.message}'`;
    showErrorMessage(message);
  }
  dispatch(hideLoader());
};

const setAsGlobalPI =
  ({ name, methodology, frequency, target, category }) =>
  async (dispatch, getState) => {
    const {
      loginDetails: { id: accessToken },
    } = getState().common;
    const {
      customPI: { id: customPIId },
    } = getState().customPIs;
    dispatch(showLoader());
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/pis/${customPIId}`,
        {
          isUserAdded: false,
          name,
          methodology,
          frequency,
          target,
          category,
        },
        { params: { access_token: accessToken } },
      );
      dispatch(closeSetGlobalPIModal());
      dispatch(fetchCustomPIs());
    } catch (error) {
      dispatch(hideLoader());
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        `Error occurred while setting PI as global due to error '${error.message}'`;
      showErrorMessage(message);
    }
  };

export {
  setCustomPIs,
  setCustomPI,
  openSetGlobalPIModal,
  closeSetGlobalPIModal,
  fetchCustomPIs,
  setAsGlobalPI,
};
