import { SET_REPORT, SET_REPORTS } from '../actions/constants';

const initialState = {
  reports: [],
  report: {},
};

const reports = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_REPORTS:
      return {
        ...state,
        reports: payload,
      };
    case SET_REPORT:
      return {
        ...state,
        report: payload,
      };
    default:
      return state;
  }
};

export default reports;
