import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';

import commonReducer from './common';
import clients from './clients';
import suppliers from './suppliers';
import internalClientUsers from './internalClientUsers';
import internalSupplierUsers from './internalSupplierUsers';
import reports from './reports';
import payments from './payments';
import admins from './admins';
import customPIs from './customPIs';

export default combineReducers({
  common: commonReducer,
  form: formReducer,
  clients,
  suppliers,
  internalClientUsers,
  internalSupplierUsers,
  reports,
  payments,
  admins,
  customPIs,
});
