import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Clients from './pages/Clients';
import Suppliers from './pages/Suppliers';
import NotFound from './pages/NotFound';
import InternalClientUsers from './pages/InternalClientUsers';
import InternalSupplierUsers from './pages/InternalSupplierUsers';
import Reports from './pages/Reports';
import Payments from './pages/Payments';
import Admins from './pages/Admins';
import SuperAdminRoute from './SuperAdminRoute';
import CustomPIs from './pages/CustomPIs';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={Clients} />
    <Route path="/clients" component={Clients} />
    <Route path="/suppliers" component={Suppliers} />
    <Route path="/internalClientUsers" component={InternalClientUsers} />
    <Route path="/internalSupplierUsers" component={InternalSupplierUsers} />
    <Route path="/reports" component={Reports} />
    <Route path="/payments" component={Payments} />
    <SuperAdminRoute path="/admins" component={Admins} />
    <Route path="/customPIs" component={CustomPIs} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
